import React, { ReactElement } from 'react';
import Layout from '../components/layout';
import SignupForm from '../components/forms/signupForm';
import SEO from '../components/seo';

const Signup = ({location}: any): ReactElement => {
    return (
        <>
            <SEO title='Signup' description='Signup for Streamlux for free to start leveling up your stream today.' />
            <SignupForm location={location} />
        </>
    );
};

export default Signup;
