import { graphql, Link, useStaticQuery } from "gatsby";
import React, { ReactElement, useContext, useState } from "react";
import LoginContainer from "./loginFormContainer";
import isEmail from "validator/es/lib/isEmail";
import { useForm } from "react-hook-form";
import { Button, Checkbox, PasswordInput, TextInput } from "luxd";
import { FormStatus } from "./forms";
import { ApiCall } from "../../appwrite/appwrite";
import { Token, Error, User } from "../../types";
import { AppwriteContext } from "../../appwriteContext";
import { AppWriteVerifyUrl } from "../../constants";
import { toast } from "react-toast";
import { gotoVerify } from "../../utils/navigation";
import { MixpanelContext } from "../../metrics";
import useAppwrite from "../../hooks/useAppwrite";

export type SignUpFormInputs = {
    username?: string;
    email: string;
    password: string;
    confirmPassword: string;
    privacyPolicy: "on" | "off";
};

const SignupForm = ({ location }: any): ReactElement => {
    const data = useStaticQuery(graphql`
        {
            allContentfulLayoutLoginForm {
                edges {
                    node {
                        id
                        heading
                        emailFieldLabel
                        passwordFieldLabel
                        forgotPasswordText
                        forgotPasswordUrl
                        signInButtonText
                        childContentfulLayoutLoginFormSubHeadingRichTextNode {
                            childContentfulRichText {
                                html
                            }
                        }
                        backgroundImage {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const appwrite = useAppwrite();
    const mixpanel = useContext(MixpanelContext);

    const { register, handleSubmit, errors, getValues } = useForm<
        SignUpFormInputs
    >();

    const [status, setStatus] = useState<FormStatus | undefined>();

    const sendVerificationEmail = () => {
        ApiCall<Token>(
            appwrite.account.createVerification(AppWriteVerifyUrl),
            (_resp: Token) => {
                setStatus("success");
                appwrite.account.updatePrefs({
                    verificationSent: true,
                });
            },
            (err: Error) => {
                toast.error("Verification email failed to send");
            }
        );
    };

    const onSubmit = (data: SignUpFormInputs) => {
        setStatus("loading");
        ApiCall<User>(
            appwrite.account.create(data.email, data.password, data.username),
            userResp => {
                setStatus("success");
                mixpanel.track("Signup", { emailVerified: false });

                appwrite.account
                    .createSession(data.email, data.password)
                    .then(() => {
                        sendVerificationEmail();
                        gotoVerify(true);
                    });
            },
            err => {
                setStatus(undefined);
                toast.error(err.message);
            }
        );
    };

    const content = data.allContentfulLayoutLoginForm.edges[0].node;

    return (
        <LoginContainer
            backgroundImage={`url(${content.backgroundImage.file.url})`}
        >
            <h1 className="text-offWhite font-medium text-xl mt-2 sm:text-2xl text-center font-display">
                Create your Streamlux account
            </h1>

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-2 sm:mt-6 flex flex-col space-y-4"
            >
                <div className="flex flex-col space-y-4 font-body">
                    <TextInput
                        id="email"
                        name={"email"}
                        label={"Email address"}
                        placeholder={"Email address"}
                        type="email"
                        required
                        inputMode="email"
                        ref={register({
                            required: "This field is required",
                            validate: value =>
                                isEmail(value) || "Enter valid email address",
                        })}
                        error={errors.email?.message}
                    />
                    <PasswordInput
                        name="password"
                        label="Password"
                        id="password"
                        ref={register({
                            required: true,
                            minLength: {
                                value: 8,
                                message:
                                    "Password must be at least 8 characters",
                            },
                        })}
                        error={errors.password?.message}
                    />
                    <PasswordInput
                        name="confirmPassword"
                        label="Confirm password"
                        id="confirmPassword"
                        ref={register({
                            required: true,
                            validate: value =>
                                value === getValues().password ||
                                "Does not match password",
                        })}
                        error={errors.confirmPassword?.message}
                    />
                </div>
                <Checkbox
                    ref={register({
                        required: { value: true, message: "Required" },
                    })}
                    id="privacyPolicy"
                    name="privacyPolicy"
                    errorText="Required"
                    className='font-body'
                    error={errors.privacyPolicy?.message}
                    label={
                        <span className="ml-2 text-sm font-body">
                            I agree to the{" "}
                            <a
                                className="hover:underline text-secondary"
                                href="/privacy"
                            >
                                Privacy Policy
                            </a>{" "}
                            and{" "}
                            <a
                                className="hover:underline text-secondary"
                                href="/terms"
                            >
                                Terms of Service
                            </a>
                        </span>
                    }
                />
                <div className='pb-2 pt-6 flex flex-row w-full justify-center'>
                    <Button
                        loading={status === "loading"}
                        color="primary"
                        size="normal"
                        className='w-full'
                    >
                        Sign up
                    </Button>
                </div>
            </form>
            <p className="mt-2 text-lg text-center font-display text-offWhite">
                Have an account?{" "}
                <Link to="/login" state={location.state} className="site-link">
                    Login
                </Link>
            </p>
        </LoginContainer>
    );
};

export default SignupForm;
